import React, { Component } from 'react';
import { logout, isLogin } from '../utils';
import { Redirect } from 'react-router-dom'

export default class Home extends Component {
  /*
  constructor(props) {
    super(props);

    //this.state = { isLogin: isLogin() };
  }
  */

  handleLogout = () => {
    logout();
    this.setState({ isLogin: false });
  }

  render() {
    return (
      <>
        <Redirect to={isLogin() ? '/analises' : '/login'} />
      </>
    );
  }
}
