import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
//import Grid from '@material-ui/core/Grid';
//import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';

//import Analise from '../components/Analise';
import axios from 'axios';
import { formatDate } from '../utils';

import NavBar from './NavBar';

const useStyles = makeStyles({
    table: {
        minWidth: 900,
    },
    clickableCell: {
      cursor: 'pointer',
    }
});

const AnalisesList = (props) => { //} extends Component {
  const [ analises, setAnalises ] = useState([]);
  const classes = useStyles();

  const history = useHistory();

  useEffect(() => {
    axios.get(
      `https://${process.env.REACT_APP_BACKEND_HOST}/api/v1/analises/`,
      {
        withCredentials: true
      }
    ).then(res => {
      if (res.data instanceof Array) {
        setAnalises(res.data);
      } else {
        setAnalises([res.data]);
      }
      //console.log(analises);
    });
  }, []);
    /*
    constructor() {
        super();
        this.getAnalises();
    }
    getAnalises = () => {
        axios.get(`http://folha.newcap.com.br/api/v1/analises/`)
        .then(res => {
            const analises = res.data;
            this.setState({analises: analises});
            console.log(this.state.analises);
        });
    */
        /*
        .catch((error) => {
          console.log("Error occurred while fetching Entries")
          console.error(error)
        })
        */
    /*
    };
    */
    /*
    onSearchInputChange = (event) => {
        console.log("Search changed ..." + event.target.value)
        if (event.target.value) {
            this.setState({searchString: event.target.value})
        } else {
            this.setState({searchString: ''})
        }
        this.getCourses()
    };
    
    render() {
        const classes = useStyles();
    */
    
    /*
    const handleClick = (data) => {
      props.history.push({
        pathname: `/analise/${data.id}`,
        state: {
          id: data.id,
          codproc: data.codproc,
          reclamante: data.reclamante,
          cliente: data.cliente
        },
      });
    };
    */
    const handleClick = (id) => {
      history.push({
        pathname: `/analise/${id}`,
        state: { id: id }
      });
    };

    return (
        <>
          <NavBar
            title="Análises"
            icon={<MenuIcon />}
            open="false"
          />
          <TableContainer component={Paper}>
            <Table stickyHeader className={classes.table} aria-label="análises">
              <TableHead>
                <TableRow>
                  <TableCell>Codproc</TableCell>
                  <TableCell>Prazo Fatal</TableCell>
                  <TableCell>Processo</TableCell>
                  <TableCell>Reclamante</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Calculista</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {analises.map((row) => (
                <TableRow
                  hover
                  key={row.codproc}
                  onClick={() => handleClick(row.id)}
                >
                  <TableCell className={classes.clickableCell} align="right">
                    {row.codproc}
                  </TableCell>
                  <TableCell className={classes.clickableCell}>
                    {formatDate(row.dtfatal, 'en-GB')}
                  </TableCell>
                  <TableCell className={classes.clickableCell}>
                    {row.processo}
                  </TableCell>
                  <TableCell className={classes.clickableCell}>
                    {row.reclamante}
                  </TableCell>
                  <TableCell className={classes.clickableCell}>
                    {row.cliente.nome}
                  </TableCell>
                  <TableCell className={classes.clickableCell}>
                    {row.calculista.nome}
                  </TableCell>
                  <TableCell className={classes.clickableCell}>
                    {row.status}
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
    );
}

export default AnalisesList;