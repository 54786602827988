/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
//import { makeStyles } from '@material-ui/core/styles';

export default function TipoRescisaoSelect(props) {
  const { style, name, options, label, value, onChange } = props;

  const [acValue, setAcValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    value && setInputValue(value.id ? `${value.descr}` : '');
  }, [value, options]);

  return (
    <Autocomplete
      name={name}
      options={options}
      autoHighlight
      getOptionLabel={(option) => option.descr}
      getOptionSelected={(option, value) => option.tipo === value.tipo}
      renderInput={(params) => (
        <TextField
          style={style}
          {...params}
          label={label}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
      value={acValue}
      onChange={(event, newValue) => {
        setAcValue(newValue);
        onChange(event, newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
}
