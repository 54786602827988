const TOKEN_KEY = 'anlUserData';

export const login = (userData) => {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(userData));
}

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
}

export const isLogin = () => {
  if (localStorage.getItem(TOKEN_KEY)) {
      return true;
  }

  return false;
}

export const isCalculista = () => {
  const userData = JSON.parse(localStorage.getItem(TOKEN_KEY));
  if (userData) {
    return userData.roles.includes('calculista');
  }
}

export const isAnalista = () => {
  const userData = JSON.parse(localStorage.getItem(TOKEN_KEY));
  if (userData) {
    console.log(userData);
    return userData.roles.includes('analista');
  }
}

export const formatDate = (date, locale)  => {
  const supportedLocales = ['en-GB', 'sv-SE'];

  if (typeof date === 'undefined'
      || !date
      || !supportedLocales.includes(locale)
      || date === '0000-00-00') {
    return ''; //' '.repeat(10);
  }

  if (locale === 'en-GB') {
    if (/\d\d\d\d-\d\d-\d\d/.test(date)) {
      const year = parseInt(date.substring(0, 4));
      const month = parseInt(date.substring(5, 7)) - 1;
      const day = parseInt(date.substring(8));

      date = new Date(year, month, day).toLocaleString(locale).substring(0, 10);
    }

    return date;
  } else if (locale === 'sv-SE') {
    if (/\d\d\/\d\d\/\d\d\d\d/.test(date)) {
      console.log('Date before untransformData:', date);
      const day = parseInt(date.substring(0, 2));
      const month = parseInt(date.substring(3, 5)) - 1;
      const year = parseInt(date.substring(6));

      date = new Date(year, month, day).toLocaleString(locale).substring(0, 10);
    }

    return date;
  }
}
