import React from 'react';
import MaterialTable from 'material-table';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import DateField from './DateField';
import Typography from '@material-ui/core/Typography';

export default function DivisoresTable(props) {
  /*
   * TODO: Componentes devem ter seu próprio estado!!!
   * Ver: https://stackoverflow.com/questions/27864951/how-to-access-childs-state-in-react
   */
  const { classes, state, setState } = props;

  return (
    <MaterialTable
      title="Jornadas Específicas"
      columns={[
        {
          field: 'data_ini',
          render: rowData => {
            return (
              <Typography className={classes.dataTableCell}>
                {rowData.data_ini}
              </Typography>
            );
          },
          editComponent: props => {
            return (
              <TextField
                fullWidth
                InputProps={{
                  inputComponent: DateField,
                  className: classes.dataTablePeriodoEdit
                }}
                value={props.rowData.data_ini}
                onChange={e => props.onChange(e.target.value)}
              />
            );
          }
        },
        {
          field: 'data_fin',
          render: rowData => {
            return (
              <Typography className={classes.dataTableCell}>
                {rowData.data_fin}
              </Typography>
            );
          },
          editComponent: props => {
            return (
              <TextField
                fullWidth
                InputProps={{
                  inputComponent: DateField,
                  className: classes.dataTablePeriodoEdit
                }}
                value={props.rowData.data_fin}
                onChange={e => props.onChange(e.target.value)}
              />
            );
          }
        },
        { field: 'num_hor_mes' }
      ]}
      data={state.jorns_trab}
      components={{
        Header: props => {
          return (
            <TableHead>
              <TableRow>
                <TableCell colSpan={2} align="center">Período</TableCell>
                <TableCell>Horas/Mês</TableCell>
                <TableCell colSpan={2} align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
          );
        }
      }}
      options={{
        search: false,
        paging: false,
        sorting: false,
        actionsColumnIndex: 3,
        draggable: false
      }}
      localization={{
        body: {
          emptyDataSourceMessage: 'Nenhuma jornada',
          addTooltip: 'Nova jornada',
          editTooltip: 'Alterar jornada',
          deleteTooltip: 'Excluir jornada',
          editRow: {
            deleteText: 'Tem certeza que deseja excluir esta jornada?',
            saveTooltip: 'Confirmar',
            cancelTooltip: 'Cancelar'
          }
        },
        header: {
          actions: 'Ações'
        }
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const data = state.jorns_trab;
                data.push({
                  id: 0,
                  data_ini: newData.data_ini,
                  data_fin: newData.data_fin,
                  num_hor_mes: newData.num_hor_mes
                });
                setState({
                  ...state,
                  jorns_trab: data
                });
                resolve();
              }
              resolve();
            }, 500);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const data = state.jorns_trab;
                const index = data.indexOf(oldData);
                data[index] = newData;
                setState({
                  ...state,
                  jorns_trab: data
                });
                resolve();
              }
              resolve();
            }, 500);
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const data = state.jorns_trab;
                const index = data.indexOf(oldData);
                data.splice(index, 1);
                setState({
                  ...state,
                  jorns_trab: data
                });
                resolve();
              }
              resolve();
            }, 500);
          })
      }}
    />
  );
}
