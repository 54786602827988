/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
//import { makeStyles } from '@material-ui/core/styles';

export default function TipoVerbaSelect(props) {
  const { style, name, options, label, value, onChange } = props;

  const [acValue, setAcValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    value && setInputValue(value.id ? `${value.descricao}` : '');
  }, [value, options]);

  return (
    <Autocomplete
      name={name}
      options={options}
      autoHighlight
      getOptionLabel={(option) => option.descricao}
      getOptionSelected={(option, value) => option.mnemonico === value.mnemonico}
      renderInput={(params) => {
        return (
          <TextField
            style={style}
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        );
      }}
      value={acValue}
      onChange={(event, newValue) => {
        setAcValue(newValue);
        onChange(event, newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
}
