import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch, Link } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
//import PublicRoute from './components/PublicRoute';
import Home from './components/Home';
import Login from './components/Login';
import AnalisesList from './components/AnalisesList';
import Analise from './components/Analise';
import { logout } from './utils';

export default function App() {

  useEffect(() => {
    window.addEventListener("beforeunload", e => {
      e.preventDefault();
      logout();
    });
  });

  return (
    <>
      <Router>
        <Switch>
          <Route path="/login" exact>
            <Login />
          </Route>
          {/*
          <PrivateRoute path="/" exact>
            <Home />
          </PrivateRoute>
          <PrivateRoute path="/analises" exact>
            <AnalisesList />
          </PrivateRoute>
          <PrivateRoute path="/analise/:id" exact>
            <Analise />
          </PrivateRoute>
          */}
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/analises" exact>
            <AnalisesList />
          </Route>
          <Route path="/analise/:id" exact>
            <Analise />
          </Route>
        </Switch>
      </Router>
    </>
  );

}
