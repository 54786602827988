import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

export default function CidadeSelect(props) {
  const { style, uf, name, label, value, onChange } = props;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const res = await axios.get(
        `https://analises.newcap.com.br/api/v1/municipios/?uf=${uf.uf}`,
        {
          withCredentials: true
        }
      );
      //await sleep(1e3); // For demo purposes.
      const cidades = res.data; //await response.json();

      if (active) {
        setOptions(Object.keys(cidades).map((key) => cidades[key]));
      }
    })();

    return () => {
      active = false;
    };
  }, [loading, uf]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const [acValue, setAcValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    value && setInputValue(value.id ? `${value.nome}` : '');
  }, [value, options]);

  return (
    <Autocomplete
      name={name}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.nome === value.nome}
      getOptionLabel={(option) => option.nome}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          style={style}
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      value={acValue}
      onChange={(event, newValue) => {
        setAcValue(newValue);
        onChange(event, newValue);
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
}
