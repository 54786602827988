import React from 'react';
import MaterialTable from 'material-table';
import { TableHead, TableRow, TableCell } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import TabAuxSelect from './TabAuxSelect';
import DateField from './DateField';
import Typography from '@material-ui/core/Typography';

export default function IndicesCmTable(props) {
  /*
   * TODO: Componentes devem ter seu próprio estado!!!
   * Ver: https://stackoverflow.com/questions/27864951/how-to-access-childs-state-in-react
   */
  //const { classes, tabsAux, state, setState } = props;
  const { classes, tabsAux, value, onChange } = props;

  return (
    <MaterialTable
      title="Índices usados"
      columns={[
        {
          field: 'tab_aux',
          render: rowData => {
            return (
              <Typography className={classes.dataTableCell}>
                {rowData.tab_aux && rowData.tab_aux.descr}
              </Typography>
            );
          },
          editComponent: props => {
            return (
              <TabAuxSelect
                options={tabsAux}
                value={props.value}
                onChange={(e, newValue) => props.onChange(e, newValue)}
              />
            );
          }
        },
        {
          field: 'data_ini',
          render: rowData => {
            return (
              /*
              <DateRender
                className={classes.dataTableCell}
                value={rowData.data_ini}
              />
              */
              <Typography className={classes.dataTableCell}>
                {rowData.data_ini}
              </Typography>
            );
          },
          editComponent: props => {
            return (
              <TextField
                fullWidth
                InputProps={{
                  inputComponent: DateField,
                  className: classes.dataTablePeriodoEdit
                }}
                value={props.rowData.data_ini}
                onChange={e => props.onChange(e.target.value)}
              />
            );
          }
        }
      ]}
      data={value}
      components={{
        Header: props => {
          return (
            <TableHead>
              <TableRow>
                <TableCell>Tabela Auxiliar</TableCell>
                <TableCell>A partir de</TableCell>
                <TableCell colSpan={2} align="center">Ações</TableCell>
              </TableRow>
            </TableHead>
          );
        }
      }}
      options={{
        search: false,
        paging: false,
        sorting: false,
        actionsColumnIndex: 2,
        draggable: false
      }}
      localization={{
        body: {
          emptyDataSourceMessage: 'Nenhum índice',
          addTooltip: 'Novo índice',
          editTooltip: 'Alterar índice',
          deleteTooltip: 'Excluir índice',
          editRow: {
            deleteText: 'Tem certeza que deseja excluir este índice?',
            saveTooltip: 'Confirmar',
            cancelTooltip: 'Cancelar'
          }
        },
        header: {
          actions: 'Ações'
        }
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const data = value;
                data.push({
                  id: 0,
                  tab_aux: newData.tab_aux,
                  data_ini: newData.data_ini
                });
                onChange(data);
                resolve();
              }
              resolve();
            }, 500);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const data = value;
                const index = data.indexOf(oldData);
                data[index] = newData;
                onChange(data);
                resolve();
              }
              resolve();
            }, 500);
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              {
                const data = value;
                const index = data.indexOf(oldData);
                data.splice(index, 1);
                onChange(data);
                resolve();
              }
              resolve();
            }, 500);
          })
      }}
    />
  );
}
