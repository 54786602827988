import React, { useState } from 'react';
//import { Redirect } from 'react-router-dom';
//import { MemoryRouter as Router } from 'react-router';
//import { Link as RouterLink } from 'react-router-dom';
import { useHistory, useLocation } from "react-router";
import { Paper, Grid, TextField, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
//import { Face, Fingerprint } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { login } from '../utils';

import axios from 'axios';
import qs from 'querystring';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: '#fafafa',
    flex: 1,
  },
  title: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    marginTop: '15%',
  },
  loginBox: {
    padding: 50,
    marginTop: 50,
    marginBottom: '13%',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
  },
  message: {
    color: '#f00',
    fontSize: '0.8rem',
    minHeight: '0.8rem',
  }
});

/*
const LinkBehavior = React.forwardRef((props, ref) => (
  <RouterLink ref={ref} to="/analises" {...props} />
));
*/

/*
async function loginRequest(data) {
  ipcRenderer.send('login-request', data);
}

ipcRenderer.on('login-response', (e, data) => {
  login(data.data.usuario.hash);
  props.history.push('/analises');
});
*/

export default function Login(props) {
  const classes = useStyles();

  /*
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  */
  const [state, setState] = useState({});
  //const [loginOk, setLoginOk] = useState(false);

  const history = useHistory();
  const location = useLocation();
  const { from } = location.state || { from: { pathname: '/' } };

  const setMessage = (msg) => {
    document.getElementById('message').innerText = msg;
  }

  const handleInput = (e) => {
    setMessage('');
  }

  const handleOnChange = (e) => {
    setState({
      ...state,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        `https://${process.env.REACT_APP_BACKEND_HOST}/api/v1/login/`,
        qs.stringify({
          _type: 'login',
          usuario: state.usuario,
          senha: state.senha,
        }),
      );
      console.debug(response.data.usuario);
      login(response.data.usuario);

      // Se estiver vindo de uma análise precisa enviar o id no state de from
      if (from.pathname) {
        const path = from.pathname.split('/');
        if (path[1] === 'analise') {
          from.state = {
            id: parseInt(path[2])
          };
        }
      }
      history.push(from);
    } catch (e) {
      /*
      const status = e.response.status;
      if (status === 400 || status === 403) {
        console.log('Usuário e/ou senha inválidos');
      } else if (status === 500) {
        console.log('Ocorreu um erro no servidor');
      }
      */
     //console.log(e);
     const status = e.response.status;
     if (status === 400 || status === 403) {
      setMessage('Usuário e/ou senha inválidos');
     } else if (status === 500) {
      setMessage('Ocorreu um erro no servidor. Tente novamente.');
     }
    }
    /*
    const net = require('electron').remote.net;
    const request = net.request({
      method: 'POST',
      protocol: 'http:',
      hostname: 'folha.newcap.com.br',
      port: 80,
      path: 'api/v1/login/',
    });
    request.write(qs.stringify({
      _type: 'login',
      usuario: usuario,
      senha: senha,
    }));
    request.end();
    request.on('response', (response) => {
      const data = '';
      if (response.statusCode === 200) {
        response.on('data', (chunk) => {
          data += chunk;
        });
        response.on('end', () => {
          data = JSON.parse(data);
          //login(response.data.usuario.hash);
          //props.history.push('/analises');
          console.log(data);
        });
      }
      response.on('error', (error) => {
        console.log(`ClientRequest error: ${JSON.stringify(error)}`);
      });
    });
    */
  }

  return (
    <>
      <div className={classes.container}>
      <Typography variant="h4" color="inherit" className={classes.title}>
        Módulo de Análises
      </Typography>
      <Paper className={classes.loginBox}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={8} alignItems="flex-end">
            <Grid item md={true} sm={true} xs={true}>
                <TextField
                  name="usuario"
                  label="Usuário"
                  value={state.usuario || ''}
                  onInput={handleInput}
                  onChange={handleOnChange}
                  fullWidth
                  autoFocus
                  required
                />
            </Grid>
          </Grid>
          <Grid container spacing={8} alignItems="flex-end">
              <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    name="senha"
                    label="Senha"
                    type="password"
                    value={state.senha || ''}
                    onInput={handleInput}
                    onChange={handleOnChange}
                    fullWidth
                    required
                  />
              </Grid>
          </Grid>
          <Grid container spacing={8} alignItems="center" justify="center">
              <Grid item>
                <Typography id="message" className={classes.message}>
                </Typography>
              </Grid>
          </Grid>
          <Grid container justify="center" style={{ marginTop: '30px' }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                textTransform: "none",
                paddingLeft: 50,
                paddingRight: 50,
              }}
            >
              Entrar
            </Button>
          </Grid>
        </form>
      </Paper>
      </div>
    </>
  )
};
