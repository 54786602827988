import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import NavBar from './NavBar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DoneIcon from '@material-ui/icons/Done';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import MaterialTable from 'material-table';
import MaskedInput from 'react-text-mask';
import DateField from './DateField';
import { Icon, TableHead, TableRow, TableCell } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MotivoSolicitacaoSelect from './MotivoSolicitacaoSelect';
//import Autocomplete from '@material-ui/lab/Autocomplete';
import TipoCmSelect from './TipoCmSelect';
import UfSelect from './UfSelect';
import CidadeSelect from './CidadeSelect';
import TipoRescisaoSelect from './TipoRescisaoSelect';
import JornadaTrabalhoSelect from './JornadaTrabalhoSelect';
import TipoVerbaSelect from './TipoVerbaSelect';
import TipoCartaoSelect from './TipoCartaoSelect';
import TipoAprCalcSelect from './TipoAprCalcSelect';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { predefinedOptions } from '@unicef/material-ui-currency-textfield';
import ParteProcSelect from './ParteProcSelect';
import BrutLiqSelect from './BrutLiqSelect';
import QualTotalSelect from './QualTotalSelect';
import IndicesCmTable from './IndicesCmTable';
import DivisoresTable from './DivisoresTable';

import { formatDate, isCalculista, isAnalista } from '../utils';

import axios from 'axios';
import qs from 'qs';
import { render } from '@testing-library/react';

const useStyles = makeStyles((theme) => ({
  buttons: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  section: {
    margin: 3,
    marginTop: 10,
  },
  cardHeader: {
      backgroundColor: '#f4f4f4',
  },
  checkbox: {
    marginTop: 5,
  },
  dataTableCell: {
    fontSize: '0.875rem',
  },
  dataTableEdit: {
    fontSize: '13px',
  },
  dataTableDias: {
    spacing: 3,
  },
  dataTablePeriodoEdit: {
    fontSize: '13px',
    width: '75px',
  },
  dataTableVerbaEdit: {
    fontSize: '13px',
    width: '18rem',
  }
}));
/*
function DateField(props) {
  const { inputRef, ...other } = props;

  function mask(rawValue) {
    let mask = [/[0-3]/, /\d/, '/', /[0-1]/, /[1-9]/, '/', /[1-2]/, /\d/, /\d/, /\d/];

    if (rawValue.substring(0, 1) === '3') {
      mask[1] = /[01]/;
    }

    const day = parseInt(rawValue.substring(0, 2));

    if (day === 31) {
      mask[4] = /[0123578]/;
    }

    if (rawValue.substring(3, 4) === '1') {
      if (day === 31) {
        mask[4] = /[02]/;
      } else {
        mask[4] = /[012]/;
      }
    } else if (day > 29) {
      if (day === 31) {
        mask[4] = /[13578]/;
      } else {
        mask[4] = /[13456789]/;
      }
    }

    return mask;
  }

  //console.log({...other});
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={true}
      mask={mask}
      placeholderChar={'\u2000'}
    />
  );
}

DateField.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
*/
function TimeField(props) {
  const { inputRef, ...other } = props;

  function mask(rawValue) {
    let mask = [/[012]/, /\d/, ':', /[0-5]/, /\d/];

    if (rawValue.substring(0, 1) === '2') {
      mask[1] = /[0-3]/;
    }

    return mask;
  }

  //console.log({...other});
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={true}
      mask={mask}
      placeholderChar={'\u2000'}
    />
  );
}

TimeField.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

function DateRender(props) {
  const { className, value } = props;

  return (
    <Typography className={className}>{value}</Typography>
  );
}

function DayCheckRender(props) {
  const { labelClass, label, icon } = props;

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography className={labelClass}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Icon>{icon}</Icon>
      </Grid>
    </Grid>
  );
}

function DayCheckEdit(props) {
  const { labelClass, label, checked } = props;

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography className={labelClass}>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Checkbox
          color="secondary"
          checked={checked}
          onChange={e => props.onChange(e.target.checked)}
        />
      </Grid>
    </Grid>
  );
}

class Analise extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      deps: {
        jorns_trab: [],
        mots_solic: [],
        tabs_aux: [],
        tipos_cm: [],
        tipos_rescis: [],
        tipos_verba: [],
        ufs: []
      },
      analise: {
        id: 0,
        parte: '',
        solicitacao_calculo: {
          codproc: 0,
          processo: '',
          gcpj: '',
          reclamante: '',
          reclamada: '',
          cliente: {
            codigo: 0,
            nome: ''
          },
          calculista: {
            id: 0,
            nome: '',
            alias: ''
          }
        },
        moti_solic: {
          id: 0,
          mnemonico: '',
          nome: ''
        },
        data_atu: '',
        tipo_cm: {
          id: 0,
          tipo: '',
          descr: ''
        },
        indi_cm: [],
        num_vara: '',
        uf_vara: {
          id: 0,
          uf: '',
          nome: ''
        },
        cid_vara: {
          id: 0,
          nome: '',
          uf: ''
        },
        uf_tab_fer: {
          id: 0,
          uf: '',
          nome: ''
        },
        cid_tab_fer: {
          id: 0,
          nome: '',
          uf: ''
        },
        ini_calc_fgts: '',
        admissao: '',
        demissao: '',
        ini_calc: '',
        fim_calc: '',
        ajuizamento: '',
        prescr_quin: false,
        tip_rescis: {
          id: 0,
          tipo: '',
          descr: ''
        },
        dias_av_prev: 0,
        data_calc: '',
        hor_nor_sem: 0,
        sab_dsr: false,
        jorn_trab: {
          id: 0,
          cod: '',
          descr: '',
          ordem: ''
        },
        hor_nor_dia: 0,
        jorns_trab: [],
        perc_hon_adv: 0,
        desc_hon_adv: '',
        brlq_hon_adv: '',
        no_hon_adv: '',
        dtde_hon_per: '',
        val_hon_per: 0,
        desc_hon_per: '',
        perc_resp_sub: 0,
        perc_resp_sol: 0,
        tip_apr_calc: '',
        tip_cart: '',
        comp_fer_fal_car: false,
        proj_he: [],
        proj_hs: [],
        reduc_hor_not: false,
        hor_not_como_diu: false,
        hor_not_apos_5h: false,
        hor_not_apos_5h_2: false,
        mens_dias_uteis: false,
        mens_dias_corridos: false,
        mens_dias_sem_fer: false,
        cons_min_atr_ant: false,
        dia_ini_mes_cartao: 0,
        tipo_ap_he_1: false,
        tipo_ap_he_2: false,
        tipo_ap_he_3: false,
        tipo_ap_he_4: false,
        tipo_ap_he_5: false,
        tipo_ap_he_5_horas: 0,
        tipo_ap_he_6: false,
        sal_base_n: [],
        sal_base_o: [],
        verbas: [],
        status: '',
        concluida_em: ''
      }
    };

    /*
    this.location = useLocation();
    this.history = useHistory();
    this.classes = useStyles();
    */

    this.wrapper = React.createRef();
  
    //this.id = this.location.state.id;

    //this.solicCalc = useRef(null);
    this.solicCalc = {
      current: {
        codproc: 0,
        processo: '',
        gcpj: '',
        reclamante: '',
        reclamada: '',
        cliente: {
          codigo: 0,
          nome: '',
        },
        calculista: {
          id: 0,
          nome: '',
          alias: ''
        }
      }
    };

    this.handleAnaliseChange = this.handleAnaliseChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.iconClick = this.iconClick.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
  }

  /*
  getData() {
    axios.get(
      `https://${process.env.REACT_APP_BACKEND_HOST}/api/v1/analises/?id=${this.id}`,
      {
        withCredentials: true
      }
    )
    .then(res => {
      //console.log('props', props);
      console.log('Remote data before transformData', res.data);
      res.data = this.transformData(res.data);
      console.log('Remote data after transformData', res.data);
      this.setState({
        analise: res.data
      });

      this.solicCalc.current = res.data.solicitacao_calculo;
      console.log('solicCalc', this.solicCalc);
      const sc = this.solicCalc.current;

      // Pega dados dos quais a análise depende
      axios.get(
        `https://${process.env.REACT_APP_BACKEND_HOST}/api/v1/analises/deps/`,
        {
          withCredentials: true
        }
      )
      .then(res => {
        this.setState({
          deps: res.data
        });
      })
      .catch(err => {
        console.log(err);
      });

      // Se for calculista pega dados do cálculo do Calctrab
      if (isCalculista()) {
        const folder = sc.reclamante.toLowerCase().replace(/ /gi, '_');
        console.log(`http://localhost:9401/calculos/${sc.cliente.codigo}/${folder}/${sc.codproc}`);
        axios.get(
          `http://localhost:9401/calculos/${sc.cliente.codigo}/${folder}/${sc.codproc}`
        )
        .then(res2 => {
          res2.data._type = res.data._type;
          res2.data.id = res.data.id;
          res2.data.parte = res.data.parte;
          res2.data.status = res.data.status;
          res2.data.codproc = res.data.codproc;
          res2.data.processo = res.data.processo;
          res2.data.reclamante = res.data.reclamante;
          res2.data.reclamada = res.data.reclamada;
          res2.data.gcpj = res.data.gcpj;
          res2.data.calculista = res.data.calculista;
          res2.data.sal_base_o = res.data.sal_base_o;

          console.log('Local data before transformData', res2.data);
          res2.data = this.transformData(res2.data);
          console.log('Local data after transformData', res2.data);
          this.setState(res2.data);
        })
        .catch(err => {
          console.log(err);
        });
      }
    })
    .catch(err => {
      console.log(err);
    });
  }
  */

  // Pega dados dos quais a análise depende
  getDeps() {
    return (
      axios.get(
        `https://${process.env.REACT_APP_BACKEND_HOST}/api/v1/analises/deps/`,
        {
          withCredentials: true
        }
      )
    );
  }

  // Pega dados da análise
  getData() {
    return (
      axios.get(
        `https://${process.env.REACT_APP_BACKEND_HOST}/api/v1/analises/?id=${this.id}`,
        {
          withCredentials: true
        }
      )
    );
  }

  // Se for calculista pega dados do cálculo do Calctrab
  getCalctrabData(sc) {
    const folder = sc.reclamante.toLowerCase().replace(/ /gi, '_');
    console.debug(`http://localhost:9401/calculos/${sc.cliente.codigo}/${folder}/${sc.codproc}`);
    return (
      axios.get(
        `http://localhost:9401/calculos/${sc.cliente.codigo}/${folder}/${sc.codproc}`
      )
    );
  }

  async componentDidMount() {
    try {
      const resp = await this.getDeps();

      console.debug('Remote dependencies', resp.data);

      /*this.state.deps.mots_solic.push(this.state.analise.moti_solic);
      this.state.deps.tipos_cm.push(this.state.analise.tipo_cm);
      */
      
      this.setState({
        deps: resp.data
      });
    } catch (err) {
      console.error(err);
    }

    try {
      const resp = await this.getData();

      //console.log('props', props);
      console.debug('Remote data before transformData', resp.data);
      resp.data = this.transformData(resp.data);
      console.debug('Remote data after transformData', resp.data);

      this.solicCalc.current = resp.data.solicitacao_calculo;
      console.debug('solicCalc', this.solicCalc);

      this.setState({
        analise: resp.data
      }, async () => {
        if (!isCalculista()) {
          return;
        }
        
        try {
          const ctResp = await this.getCalctrabData(this.solicCalc.current);

          ctResp.data._type = resp.data._type;
          ctResp.data.id = resp.data.id;
          ctResp.data.parte = resp.data.parte;
          ctResp.data.status = resp.data.status;
          ctResp.data.codproc = resp.data.codproc;
          ctResp.data.processo = resp.data.processo;
          ctResp.data.reclamante = resp.data.reclamante;
          ctResp.data.reclamada = resp.data.reclamada;
          ctResp.data.gcpj = resp.data.gcpj;
          ctResp.data.calculista = resp.data.calculista;
          ctResp.data.sal_base_o = resp.data.sal_base_o;
    
          console.debug('Local data before transformData', ctResp.data);
          ctResp.data = this.transformData(ctResp.data);
          console.debug('Local data after transformData', ctResp.data);
          this.setState({
            analise: ctResp.data
          });
        } catch (err) {
          console.error(err);
        }
      });
    } catch (err) {
      console.error(err);
    }

    console.debug('componentDidMount');
  }

  componentWillUnmount() {

  }

  //const { id, codproc, reclamante, cliente } = props.location.state;
  //const id = props.match.params.id;
  /*
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const wrapper = React.createRef();
  */

  /*
  const { id } = location.state;
  */

  /*
  const [ analise, setAnalise ] = useState({});
  const [ deps, setDeps ] = useState({});
  //const [ changed, setChanged ] = useState(false);
  const [ saving, setSaving ] = useState(false);
  */

  /*
  const isFirstRender = useRef(true);
  const solicCalc = useRef(null);
  */

  handleAnaliseChange(e) {
    this.setState({
      analise: {
        ...this.state.analise,
        [e.currentTarget.name]: e.currentTarget.value
      }
    });
  }

  handleCheckboxChange(e) {
    this.setState({
      analise: {
        ...this.state.analise,
        [e.currentTarget.name]: e.currentTarget.checked
      }
    });
  }

  transformData(data) {
    if (typeof data.data_atu === 'undefined') {
      return data;
    }

    const locale = 'en-GB';

    data.data_atu = formatDate(data.data_atu, locale);
    data.ini_calc_fgts = formatDate(data.ini_calc_fgts, locale);
    data.admissao = formatDate(data.admissao, locale);
    data.demissao = formatDate(data.demissao, locale);
    data.ini_calc = formatDate(data.ini_calc, locale);
    data.fim_calc = formatDate(data.fim_calc, locale);
    data.ajuizamento = formatDate(data.ajuizamento, locale);
    data.data_calc = formatDate(data.data_calc, locale);
    data.dtde_hon_per = formatDate(data.dtde_hon_per, locale);

    data.proj_he.forEach(element => {
      element.data_ini = formatDate(element.data_ini, locale);
      element.data_fin = formatDate(element.data_fin, locale);
    });

    data.proj_hs.forEach(element => {
      element.data_ini = formatDate(element.data_ini, locale);
      element.data_fin = formatDate(element.data_fin, locale);
    });

    data.verbas.forEach(element => {
      element.data_ini = formatDate(element.data_ini, locale);
      element.data_fin = formatDate(element.data_fin, locale);
    });

    return data;
  }

  untransformData(data) {
    if (typeof data.data_atu === 'undefined') {
      return data;
    }

    const locale = 'sv-SE';

    data.data_atu = formatDate(data.data_atu, locale);
    data.ini_calc_fgts = formatDate(data.ini_calc_fgts, locale);
    data.admissao = formatDate(data.admissao, locale);
    data.demissao = formatDate(data.demissao, locale);
    data.ini_calc = formatDate(data.ini_calc, locale);
    data.fim_calc = formatDate(data.fim_calc, locale);
    data.ajuizamento = formatDate(data.ajuizamento, locale);
    data.data_calc = formatDate(data.data_calc, locale);
    data.dtde_hon_per = formatDate(data.dtde_hon_per, locale);

    data.proj_he.forEach(element => {
      element.data_ini = formatDate(element.data_ini, locale);
      element.data_fin = formatDate(element.data_fin, locale);
    });

    data.proj_hs.forEach(element => {
      element.data_ini = formatDate(element.data_ini, locale);
      element.data_fin = formatDate(element.data_fin, locale);
    });

    data.verbas.forEach(element => {
      element.data_ini = formatDate(element.data_ini, locale);
      element.data_fin = formatDate(element.data_fin, locale);
    });

    return data;
  }

  /*
  useEffect(() => {
    axios.get(
      `https://${process.env.REACT_APP_BACKEND_HOST}/api/v1/analises/?id=${id}`,
      {
        withCredentials: true
      }
    )
    .then(res => {
      //console.log('props', props);
      console.log('Remote data before transformData', res.data);
      res.data = transformData(res.data);
      console.log('Remote data after transformData', res.data);
      setAnalise(res.data);

      solicCalc.current = res.data.solicitacao_calculo;
      console.log('solicCalc', solicCalc);
      const sc = solicCalc.current;

      if (isCalculista()) {
        const folder = sc.reclamante.toLowerCase().replace(/ /gi, '_');
        console.log(`http://localhost:9401/calculos/${sc.cliente.codigo}/${folder}/${sc.codproc}`);
        axios.get(
          `http://localhost:9401/calculos/${sc.cliente.codigo}/${folder}/${sc.codproc}`
        )
        .then(res2 => {
          res2.data._type = res.data._type;
          res2.data.id = res.data.id;
          res2.data.parte = res.data.parte;
          res2.data.status = res.data.status;
          res2.data.codproc = res.data.codproc;
          res2.data.processo = res.data.processo;
          res2.data.reclamante = res.data.reclamante;
          res2.data.reclamada = res.data.reclamada;
          res2.data.gcpj = res.data.gcpj;
          res2.data.calculista = res.data.calculista;
          res2.data.sal_base_o = res.data.sal_base_o;

          console.log('Local data before transformData', res2.data);
          res2.data = transformData(res2.data);
          console.log('Local data after transformData', res2.data);
          setAnalise(res2.data);
        })
        .catch(err => {
          console.log(err);
        });
      }
    })
    .catch(err => {
      console.log(err);
    });

    // Pega dados dos quais a análise depende
    axios.get(
      `https://${process.env.REACT_APP_BACKEND_HOST}/api/v1/analises/deps/`,
      {
        withCredentials: true
      }
    )
    .then(res => {
      setDeps(res.data);
    })
    .catch(err => {
      console.log(err);
    });
  }, [id]);
  */

  /*
  useEffect(() => {
    if (typeof analise.data_atu === 'undefined') {
      return;
    }

    console.log(analise);
    console.log(untransformData(analise));
    console.log(qs.stringify(untransformData(analise), {encodeValuesOnly: true}));

    axios.put(
      `https://${process.env.REACT_APP_BACKEND_HOST}/api/v1/analises/?id=${id}`,
      qs.stringify(untransformData(analise), {encodeValuesOnly: true}),
      {
        withCredentials: true
      }
    )
    .then(res => {
      console.log('Análise salva com sucesso!');
    })
    .catch(err => {
      console.log(err);
    });
  }, [changed]);
  */

  iconClick() {
    //history.goBack();
    this.props.history.push({
      pathname: '/analises/'
    });
  }

  /*
  const setStatusTo = (newStatus) => {
    if (analise.status !== newStatus) {
      setChanged(true);
    }

    setAnalise({
      ...analise,
      status: newStatus
    });
  };
  */

  handleSave() {
    /*
    if (analise.status === 'Aguardando') {
      console.log('Alterando status para Analisando...');
      setStatusTo('Analisando');
    } else {
      setChanged(true);
    }
    */
    //if (analise.status === 'Aguardando') {
    //  console.log('Alterando status para Analisando...');
      this.setState({
        analise: {
          ...this.state.analise,
          status: this.state.analise.status === 'Aguardando'
            ? 'Analisando'
            : this.state.analise.status
        }
      },
      () => {
        const { analise } = this.state;
        console.log('Salvando...', analise);
        console.log(this.untransformData(analise));
        console.log(qs.stringify(this.untransformData(analise), {encodeValuesOnly: true}));

        axios.put(
          `https://${process.env.REACT_APP_BACKEND_HOST}/api/v1/analises/?id=${analise.id}`,
          qs.stringify(this.untransformData(analise), {encodeValuesOnly: true}),
          {
            withCredentials: true
          }
        )
        .then(res => {
          console.log('Análise salva com sucesso!');
        })
        .catch(err => {
          console.log(err);
        });
      });
    //}
  }

  handleDone() {
    this.setState({
      analise: {
        ...this.state.analise,
        status: 'Concluida'
      }
    },
    () => {
      const { analise } = this.state;
      console.log('Concluindo...', analise);
      console.log(this.untransformData(analise));
      console.log(qs.stringify(this.untransformData(analise), {encodeValuesOnly: true}));

      axios.put(
        `https://${process.env.REACT_APP_BACKEND_HOST}/api/v1/analises/?id=${analise.id}`,
        qs.stringify(this.untransformData(analise), {encodeValuesOnly: true}),
        {
          withCredentials: true
        }
      )
      .then(res => {
        console.log('Análise concluída com sucesso!');
      })
      .catch(err => {
        console.log(err);
      });
    });
  }

  handleValidate() {

  }

  render() {
    if (this.props.location.state) {
      this.id = this.props.location.state.id;
    }
    this.classes = this.props.classes;

    const analise = this.state.analise;
    const deps = this.state.deps;
    const solicCalc = this.solicCalc.current;
    
    const codproc = solicCalc ? `- Codproc ${solicCalc.codproc}` : '';

    console.debug('render');

    return(
      <div ref={this.wrapper}>
        { this.id ? (
          <div ref={this.wrapper}>

            <NavBar
              title={`Análise ${codproc}`}
              icon={<ArrowBackIcon />}
              iconClick={this.iconClick}
            >
              {/*<div className={this.classes.buttons}>*/}
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SaveIcon />}
                  onClick={this.handleSave}
                >
                  Salvar
                </Button>
              </Grid>
              <Grid item>
                { isAnalista() ?
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DoneIcon />}
                    onClick={this.handleDone}
                  >
                    Concluir
                  </Button>
                  : null }
                { isCalculista() ?
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<DoneIcon />}
                    onClick={this.handleValidate}
                  >
                    Validar
                  </Button>
                  : null }
              </Grid>
              {/*</div>*/}
            </NavBar>

            <Card className={this.classes.section}>
              <CardHeader className={this.classes.cardHeader} title="Dados do Processo" />
              {/*image={props.course.fields.courseImage.fields.file.url}*/}
              <CardContent>
                <form noValidate autoComplete="off">
                  <Grid container spacing={3}>
                    <Grid item>
                      <TextField
                        style={{width: '13rem'}}
                        name="processo"
                        label="Nº do processo"
                        fullWidth
                        disabled
                        value={solicCalc.processo}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '6rem'}}
                        name="gcpj"
                        label="GCPJ"
                        fullWidth
                        disabled
                        value={solicCalc.gcpj}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '28rem'}}
                        name="reclamante"
                        label="Reclamante"
                        fullWidth
                        disabled
                        value={solicCalc.reclamante}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '28rem'}}
                        name="reclamada"
                        label="Reclamada"
                        fullWidth
                        disabled
                        value={solicCalc.reclamada}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item>
                      <MotivoSolicitacaoSelect
                        name="moti_solic"
                        options={deps.mots_solic}
                        value={analise.moti_solic}
                        onChange={(event, newValue) => {
                          this.setState({
                            analise: {
                              ...this.state.analise,
                              moti_solic: newValue
                            }
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '9rem'}}
                        name="data_atu"
                        label="Data da atualização"
                        fullWidth
                        InputProps={{
                          inputComponent: DateField,
                        }}
                        value={analise.data_atu}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item>
                      <TipoCmSelect
                        name="tipo_cm"
                        options={deps.tipos_cm}
                        value={analise.tipo_cm}
                        onChange={(event, newValue) => {
                          this.setState({
                            analise: {
                              ...this.state.analise,
                              tipo_cm: newValue
                            }
                          });
                        }}
                      />
                    </Grid>
                    {(analise.tipo_cm ? analise.tipo_cm.tipo === 'O' : false) &&
                      <Grid item style={{width: '32rem'}}>
                        <IndicesCmTable
                          classes={this.classes}
                          tabsAux={deps.tabs_aux}
                          value={analise.indi_cm}
                          onChange={(newValue) => {
                            this.setState({
                              analise: {
                                ...this.state.analise,
                                indi_cm: newValue
                              }
                            });
                          }}
                        />
                      </Grid>
                    }
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item>
                      <TextField
                        style={{width: '5rem'}}
                        name="num_vara"
                        label="Nº da vara"
                        fullWidth
                        value={analise.num_vara}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <UfSelect
                        style={{width: '6.5rem'}}
                        name="uf_vara"
                        options={deps.ufs}
                        label="UF da vara"
                        value={analise.uf_vara}
                        onChange={(event, newValue) => {
                          this.setState({
                            analise: {
                              ...this.state.analise,
                              uf_vara: newValue
                            }
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CidadeSelect
                        style={{width: '19rem'}}
                        name="cid_vara"
                        label="Cidade da vara"
                        uf={analise.uf_vara}
                        value={analise.cid_vara}
                        onChange={(event, newValue) => {
                          this.setState({
                            analise: {
                              ...this.state.analise,
                              cid_vara: newValue
                            }
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <UfSelect
                        style={{width: '8rem'}}
                        name="uf_tab_fer"
                        options={deps.ufs}
                        label="UF p/ feriados"
                        value={analise.uf_tab_fer}
                        onChange={(event, newValue) => {
                          this.setState({
                            analise: {
                              ...this.state.analise,
                              uf_tab_fer: newValue
                            }
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <CidadeSelect
                        style={{width: '19rem'}}
                        name="cid_tab_fer"
                        label="Cidade p/ feriados"
                        uf={analise.uf_tab_fer}
                        value={analise.cid_tab_fer}
                        onChange={(event, newValue) => {
                          this.setState({
                            analise: {
                              ...this.state.analise,
                              cid_tab_fer: newValue
                            }
                          });
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item>
                      <TextField
                        style={{width: '10.5rem'}}
                        name="ini_calc_fgts"
                        label="Início de cálculo FGTS"
                        fullWidth
                        InputProps={{
                          inputComponent: DateField,
                        }}
                        value={analise.ini_calc_fgts}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '6rem'}}
                        name="admissao"
                        label="Admissão"
                        fullWidth
                        InputProps={{
                          inputComponent: DateField,
                        }}
                        value={analise.admissao}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '6rem'}}
                        name="demissao"
                        label="Demissão"
                        fullWidth
                        InputProps={{
                          inputComponent: DateField,
                        }}
                        value={analise.demissao}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '8rem'}}
                        name="ini_calc"
                        label="Início de cálculo"
                        fullWidth
                        InputProps={{
                          inputComponent: DateField,
                        }}
                        value={analise.ini_calc}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '7rem'}}
                        name="fim_calc"
                        label="Fim de cálculo"
                        fullWidth
                        InputProps={{
                          inputComponent: DateField,
                        }}
                        value={analise.fim_calc}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '9.5rem'}}
                        name="ajuizamento"
                        label="Data de ajuizamento"
                        fullWidth
                        InputProps={{
                          inputComponent: DateField,
                        }}
                        value={analise.ajuizamento}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={this.classes.checkbox}
                            name="prescr_quin"
                            checked={analise.prescr_quin ? true : false}
                            onChange={this.handleCheckboxChange}
                          />}
                        label="Prescrição quinquenal"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item>
                      <TipoRescisaoSelect
                        style={{width: '22rem'}}
                        name="tip_rescis"
                        options={deps.tipos_rescis}
                        label="Tipo de rescisão"
                        value={analise.tip_rescis}
                        onChange={(event, newValue) => {
                          this.setState({
                            analise: {
                              ...this.state.analise,
                              tip_rescis: newValue
                            }
                          });
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '8rem'}}
                        name="dias_av_prev"
                        label="Dias aviso prévio"
                        fullWidth
                        value={analise.dias_av_prev}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '16rem'}}
                        name="calculista"
                        label="Calculista"
                        fullWidth
                        disabled
                        value={solicCalc.calculista.nome}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '7.5rem'}}
                        name="data_calc"
                        label="Data do cálculo"
                        fullWidth
                        InputProps={{
                          inputComponent: DateField,
                        }}
                        value={analise.data_calc}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        style={{width: '11.5rem'}}
                        name="hor_nor_sem"
                        label="Horas normais semanais"
                        fullWidth
                        value={analise.hor_nor_sem}
                        onChange={this.handleAnaliseChange}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Checkbox
                            className={this.classes.checkbox}
                            name="sab_dsr"
                            checked={analise.sab_dsr ? true : false}
                            onChange={this.handleCheckboxChange}
                          />}
                        label="Sábados compõem DSR"
                      />
                    </Grid>
                    <Grid item>
                      <JornadaTrabalhoSelect
                        style={{width: '14.5rem'}}
                        name="jorn_trab"
                        options={deps.jorns_trab}
                        label="Divisor"
                        value={analise.jorn_trab}
                        onChange={(event, newValue) => {
                          this.setState({
                            analise: {
                              ...this.state.analise,
                              jorn_trab: newValue
                            }
                          });
                        }}
                      />
                    </Grid>
                    <Grid item style={{width: '32rem'}}>
                      {(analise.jorn_trab ? analise.jorn_trab.cod !== '99' : true) &&
                        <TextField
                          style={{width: '10.1rem'}}
                          name="hor_nor_dia"
                          label="Horas normais diárias"
                          fullWidth
                          value={analise.hor_nor_dia}
                          onChange={this.handleAnaliseChange}
                        />
                      }
                      {(analise.jorn_trab ? analise.jorn_trab.cod === '99' : false) &&
                        <DivisoresTable
                          classes={this.classes}
                          state={analise}
                          setState={this.setState}
                        />
                      }
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Card
                        style={{
                          padding: '0.3rem'
                        }}
                      >
                        <Typography
                          style={{
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            fontSize: '1.2rem',
                            display: 'block'}}
                        >
                          Honorários advocatícios
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item>
                            <TextField
                              style={{width: '2rem'}}
                              name="perc_hon_adv"
                              label="%"
                              fullWidth
                              value={analise.perc_hon_adv}
                              onChange={this.handleAnaliseChange}
                            />
                          </Grid>
                          <Grid item>
                            <ParteProcSelect
                              style={{width: '9rem'}}
                              name="desc_hon_adv"
                              label="A cargo de"
                              value={analise.desc_hon_adv}
                              onChange={(event, newValue) => {
                                this.setState({
                                  analise: {
                                    ...this.state.analise,
                                    desc_hon_adv: newValue['option']
                                  }
                                });
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <BrutLiqSelect
                              style={{width: '8rem'}}
                              name="brlq_hon_adv"
                              label="Sobre Crédito"
                              value={analise.brlq_hon_adv}
                              onChange={(event, newValue) => {
                                this.setState({
                                  analise: {
                                    ...this.state.analise,
                                    brlq_hon_adv: newValue['option']
                                  }
                                });
                              }}
                            />
                          </Grid>
                          <Grid item>
                            <QualTotalSelect
                              style={{width: '20rem'}}
                              name="no_hon_adv"
                              label="Somar/Abater"
                              value={analise.no_hon_adv}
                              onChange={(event, newValue) => {
                                this.setState({
                                  analise: {
                                    ...this.state.analise,
                                    no_hon_adv: newValue['option']
                                  }
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                    <Grid item>
                      <Card
                        style={{
                          padding: '0.3rem'
                        }}
                      >
                        <Typography
                          style={{
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            fontSize: '1.2rem',
                            display: 'block'}}
                        >
                          Honorários periciais
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item>
                            <TextField
                              style={{width: '8rem'}}
                              name="dtde_hon_per"
                              label="Data da descisão"
                              fullWidth
                              InputProps={{
                                inputComponent: DateField,
                              }}
                              value={analise.dtde_hon_per}
                              onChange={this.handleAnaliseChange}
                            />
                          </Grid>
                          <Grid item>
                            <CurrencyTextField
                              style={{width: '8rem'}}
                              label="Valor"
                              value={analise.val_hon_per}
                              currencySymbol="R$"
                              decimalCharacter=","
                              digitGroupSeparator="."
                              maximumValue="9999999.99"
                              onChange={this.handleAnaliseChange}
                            />
                          </Grid>
                          <Grid item>
                            <ParteProcSelect
                              style={{width: '9rem'}}
                              name="desc_hon_per"
                              label="Descontar de"
                              value={analise.desc_hon_per}
                              onChange={(event, newValue) => {
                                this.setState({
                                  analise: {
                                    ...this.state.analise,
                                    desc_hon_per: newValue['option']
                                  }
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item>
                      <Card
                        style={{
                          padding: '0.3rem'
                        }}
                      >
                        <Typography
                          style={{
                            paddingTop: '0.5rem',
                            paddingBottom: '0.5rem',
                            fontSize: '1.2rem',
                            display: 'block'}}
                        >
                          Responsabilidade sobre o cálculo
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item>
                            <TextField
                              style={{width: '14.5rem'}}
                              name="perc_resp_sub"
                              label="% Responsabilidade subsidiária"
                              fullWidth
                              value={analise.perc_resp_sub}
                              onChange={this.handleAnaliseChange}
                            />
                          </Grid>
                          <Grid item>
                            <TextField
                              style={{width: '14.5rem'}}
                              name="perc_resp_sol"
                              label="% Responsabilidade solidária"
                              fullWidth
                              value={analise.perc_resp_sol}
                              onChange={this.handleAnaliseChange}
                            />
                          </Grid>
                          <Grid item>
                            <TipoAprCalcSelect
                              style={{width: '16rem'}}
                              name="tipo_apr_calc"
                              label="Cálculo apresentado"
                              value={analise.tipo_apr_calc}
                              onChange={(event, newValue) => {
                                this.setState({
                                  analise: {
                                    ...this.state.analise,
                                    tipo_apr_calc: newValue['option']
                                  }
                                });
                              }}
                            />
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>

            <Card className={this.classes.section}>
              <CardHeader className={this.classes.cardHeader} title="Opções do Cartão de Ponto" />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item>
                    <TipoCartaoSelect
                      style={{width: '8rem'}}
                      name="tip_cart"
                      label="Cartão"
                      value={analise.tip_cart}
                      onChange={(event, newValue) => {
                        this.setState({
                          analise: {
                            ...this.state.analise,
                            tip_cart: newValue['option']
                          }
                        });
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="comp_fer_fal_car"
                          checked={analise.comp_fer_fal_car ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Computadas férias/faltas cartão"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      style={{ width: '9.5rem' }}
                      name="dia_ini_mes_cartao"
                      label="Início do mês no dia"
                      value={analise.dia_ini_mes_cartao}
                      onChange={this.handleAnaliseChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="reduc_hor_not"
                          checked={analise.reduc_hor_not ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Considerar redução da hora noturna"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="hor_not_como_diu"
                          checked={analise.hor_not_como_diu ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Considerar todas horas noturnas como diurnas"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="hor_not_apos_5h"
                          checked={analise.hor_not_apos_5h ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Prorrogar horas noturnas após às 5h"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="hor_not_apos_5h_2"
                          checked={analise.hor_not_apos_5h_2 ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Considerar horas após às 5h como noturnas só quando cumprida a jornada das 22h às 5h integralmente"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="mens_dias_uteis"
                          checked={analise.mens_dias_uteis ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Mensal baseado em dias úteis"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="mens_dias_corridos"
                          checked={analise.mens_dias_corridos ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Mensal baseado em dias corridos"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="mens_dias_sem_fer"
                          checked={analise.mens_dias_sem_fer ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Mensal em dias da semana/feriados"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="cons_min_atr_ant"
                          checked={analise.cons_min_atr_ant ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Considerar minutos em atraso/antecipação"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card className={this.classes.section}>
              <CardHeader className={this.classes.cardHeader} title="Projeção do Cartão de Ponto" />
              <CardContent>
                <MaterialTable
                  columns={[
                    {
                      field: 'data_ini',
                      render: rowData => {
                        return (
                          <DateRender
                            className={this.classes.dataTableCell}
                            value={rowData.data_ini}
                          />
                        );
                      },
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: DateField,
                              className: this.classes.dataTablePeriodoEdit
                            }}
                            value={props.rowData.data_ini}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      field: 'data_fin',
                      render: rowData => {
                        return (
                          <DateRender
                            className={this.classes.dataTableCell}
                            value={rowData.data_fin}
                          />
                        );
                      },
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: DateField,
                              className: this.classes.dataTablePeriodoEdit
                            }}
                            value={props.rowData.data_fin}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      field: 'seg', type: 'boolean',
                      render: rowData => {
                        return (
                          <DayCheckRender
                            labelClass={this.classes.dataTableCell}
                            label="Seg"
                            icon={rowData.seg ? 'check' : 'remove'}
                          />
                        );
                      }/*,
                      editComponent: props => {
                        console.log(props);
                        return (
                          <DayCheckEdit
                            labelClass={classes.dataTableCell}
                            label="Seg"
                            checked={props.rowData.seg}
                            onChange={e => props.onChange(e.target.checked)}
                          />
                        );
                      }*/
                    },
                    {
                      field: 'ter', type: 'boolean',
                      render: rowData => {
                        return (
                          <DayCheckRender
                            labelClass={this.classes.dataTableCell}
                            label="Ter"
                            icon={rowData.ter ? 'check' : 'remove'}
                          />
                        );
                      }
                    },
                    {
                      field: 'qua', type: 'boolean',
                      render: rowData => {
                        return (
                          <DayCheckRender
                            labelClass={this.classes.dataTableCell}
                            label="Qua"
                            icon={rowData.qua ? 'check' : 'remove'}
                          />
                        );
                      }
                    },
                    {
                      field: 'qui', type: 'boolean',
                      render: rowData => {
                        return (
                          <DayCheckRender
                            labelClass={this.classes.dataTableCell}
                            label="Qui"
                            icon={rowData.qui ? 'check' : 'remove'}
                          />
                        );
                      }
                    },
                    {
                      field: 'sex', type: 'boolean',
                      render: rowData => {
                        return (
                          <DayCheckRender
                            labelClass={this.classes.dataTableCell}
                            label="Sex"
                            icon={rowData.sex ? 'check' : 'remove'}
                          />
                        );
                      }
                    },
                    {
                      title: 'Entrada', field: 'entrada1', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.entrada1}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      title: 'Saída', field: 'saida1', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.saida1}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      title: 'Entrada', field: 'entrada2', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.entrada2}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      title: 'Saída', field: 'saida2', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.saida2}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      title: 'Entrada', field: 'entrada3', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.entrada3}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      title: 'Saída', field: 'saida3', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.saida3}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    }
                  ]}
                  data={analise.proj_he}
                  components={{
                    Header: props => {
                      return (
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} align="center">Período</TableCell>
                            <TableCell colSpan={5} align="center">Dias</TableCell>
                            <TableCell>Entrada</TableCell>
                            <TableCell>Saída</TableCell>
                            <TableCell>Entrada</TableCell>
                            <TableCell>Saída</TableCell>
                            <TableCell>Entrada</TableCell>
                            <TableCell>Saída</TableCell>
                            <TableCell colSpan={2} align="center">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                      );
                    }
                  }}
                  options={{
                    showTitle: false,
                    search: false,
                    paging: false,
                    sorting: false,
                    actionsColumnIndex: 13,
                    draggable: false
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: 'Nenhuma projeção',
                      addTooltip: 'Nova projeção',
                      editTooltip: 'Alterar projeção',
                      deleteTooltip: 'Excluir projeção',
                      editRow: {
                        deleteText: 'Tem certeza que deseja excluir esta projeção?',
                        saveTooltip: 'Confirmar',
                        cancelTooltip: 'Cancelar'
                      }
                    },
                    header: {
                      actions: 'Ações'
                    }
                  }}
                  editable={{
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            console.log(newData);

                            const data = analise.proj_he;
                            data.push({
                              id: 0,
                              data_ini: newData.data_ini,
                              data_fin: newData.data_fin,
                              seg: newData.seg,
                              ter: newData.ter,
                              qua: newData.qua,
                              qui: newData.qui,
                              sex: newData.sex,
                              entrada1: newData.entrada1,
                              saida1: newData.saida1,
                              entrada2: newData.entrada2,
                              saida2: newData.saida2,
                              entrada3: newData.entrada3,
                              saida3: newData.saida3
                            });
                            this.setState({
                              analise: {
                                ...this.state.analise,
                                proj_he: data
                              }
                            });
                            resolve();
                          }
                          resolve();
                        }, 500);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = analise.proj_he;
                            const index = data.indexOf(oldData);
                            data[index] = newData;                
                            this.setState({
                              analise: {
                                ...this.state.analise,
                                proj_he: data
                              }
                            });
                            resolve();
                          }
                          resolve();
                        }, 500);
                      }),
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = analise.proj_he;
                            const index = data.indexOf(oldData);
                            data.splice(index, 1);
                            this.setState({
                              analise: {
                                ...this.state.analise,
                                proj_he: data
                              }
                            });
                            resolve();
                          }
                          resolve();
                        }, 500);
                      })
                  }}
                />
              </CardContent>
            </Card>

            <Card className={this.classes.section}>
              <CardHeader className={this.classes.cardHeader} title="Projeção do Sobreaviso" />
              <CardContent>
                <MaterialTable
                  columns={[
                    {
                      field: 'data_ini',
                      render: rowData => {
                        return (
                          <DateRender
                            className={this.classes.dataTableCell}
                            value={rowData.data_ini}
                          />
                        );
                      },
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: DateField,
                              className: this.classes.dataTablePeriodoEdit
                            }}
                            value={props.rowData.data_ini}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      field: 'data_fin',
                      render: rowData => {
                        return (
                          <DateRender
                            className={this.classes.dataTableCell}
                            value={rowData.data_fin}
                          />
                        );
                      },
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: DateField,
                              className: this.classes.dataTablePeriodoEdit
                            }}
                            value={props.rowData.data_fin}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      field: 'seg', type: 'boolean',
                      render: rowData => {
                        return (
                          <DayCheckRender
                            labelClass={this.classes.dataTableCell}
                            label="Seg"
                            icon={rowData.seg ? 'check' : 'remove'}
                          />
                        );
                      }
                    },
                    {
                      field: 'ter', type: 'boolean',
                      render: rowData => {
                        return (
                          <DayCheckRender
                            labelClass={this.classes.dataTableCell}
                            label="Ter"
                            icon={rowData.ter ? 'check' : 'remove'}
                          />
                        );
                      }
                    },
                    {
                      field: 'qua', type: 'boolean',
                      render: rowData => {
                        return (
                          <DayCheckRender
                            labelClass={this.classes.dataTableCell}
                            label="Qua"
                            icon={rowData.qua ? 'check' : 'remove'}
                          />
                        );
                      }
                    },
                    {
                      field: 'qui', type: 'boolean',
                      render: rowData => {
                        return (
                          <DayCheckRender
                            labelClass={this.classes.dataTableCell}
                            label="Qui"
                            icon={rowData.qui ? 'check' : 'remove'}
                          />
                        );
                      }
                    },
                    {
                      field: 'sex', type: 'boolean',
                      render: rowData => {
                        return (
                          <DayCheckRender
                            labelClass={this.classes.dataTableCell}
                            label="Sex"
                            icon={rowData.sex ? 'check' : 'remove'}
                          />
                        );
                      }
                    },
                    {
                      title: 'Entrada', field: 'entrada1', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.entrada1}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      title: 'Saída', field: 'saida1', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.saida1}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      title: 'Entrada', field: 'entrada2', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.entrada2}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      title: 'Saída', field: 'saida2', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.saida2}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      title: 'Entrada', field: 'entrada3', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.entrada3}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      title: 'Saída', field: 'saida3', width: 30,
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: TimeField,
                              className: this.classes.dataTableEdit
                            }}
                            value={props.rowData.saida3}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    }
                  ]}
                  data={analise.proj_hs}
                  components={{
                    Header: props => {
                      return (
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} align="center">Período</TableCell>
                            <TableCell colSpan={5} align="center">Dias</TableCell>
                            <TableCell>Entrada</TableCell>
                            <TableCell>Saída</TableCell>
                            <TableCell>Entrada</TableCell>
                            <TableCell>Saída</TableCell>
                            <TableCell>Entrada</TableCell>
                            <TableCell>Saída</TableCell>
                            <TableCell colSpan={2} align="center">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                      );
                    }
                  }}
                  options={{
                    showTitle: false,
                    search: false,
                    paging: false,
                    sorting: false,
                    actionsColumnIndex: 13,
                    draggable: false
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: 'Nenhuma projeção',
                      addTooltip: 'Nova projeção',
                      editTooltip: 'Alterar projeção',
                      deleteTooltip: 'Excluir projeção',
                      editRow: {
                        deleteText: 'Tem certeza que deseja excluir esta projeção?',
                        saveTooltip: 'Confirmar',
                        cancelTooltip: 'Cancelar'
                      }
                    },
                    header: {
                      actions: 'Ações'
                    }
                  }}
                  editable={{
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            console.log(newData);

                            const data = analise.proj_hs;
                            data.push({
                              id: 0,
                              data_ini: newData.data_ini,
                              data_fin: newData.data_fin,
                              seg: newData.seg,
                              ter: newData.ter,
                              qua: newData.qua,
                              qui: newData.qui,
                              sex: newData.sex,
                              entrada1: newData.entrada1,
                              saida1: newData.saida1,
                              entrada2: newData.entrada2,
                              saida2: newData.saida2,
                              entrada3: newData.entrada3,
                              saida3: newData.saida3
                            });
                            this.setState({
                              analise: {
                                ...this.state.analise,
                                proj_hs: data
                              }
                            });
                            resolve();
                          }
                          resolve();
                        }, 500);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = analise.proj_hs;
                            const index = data.indexOf(oldData);
                            data[index] = newData;                
                            this.setState({
                              analise: {
                                ...this.state.analise,
                                proj_hs: data
                              }
                            });
                            resolve();
                          }
                          resolve();
                        }, 500);
                      }),
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = analise.proj_hs;
                            const index = data.indexOf(oldData);
                            data.splice(index, 1);
                            this.setState({
                              analise: {
                                ...this.state.analise,
                                proj_hs: data
                              }
                            });
                            resolve();
                          }
                          resolve();
                        }, 500);
                      })
                  }}
                />
              </CardContent>
            </Card>

            <Card className={this.classes.section}>
              <CardHeader className={this.classes.cardHeader} title="Tipo de Apuração de Hora Extra" />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="tipo_ap_he_1"
                          checked={analise.tipo_ap_he_1 ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Excedente à 8ª diária e 44 semanal (usar a mais benéfica ao reclamante)"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="tipo_ap_he_2"
                          checked={analise.tipo_ap_he_2 ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Excedente à 8ª diária de 2ª à 6ª feira"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="tipo_ap_he_3"
                          checked={analise.tipo_ap_he_3 ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Excedentes às 44 horas semanais"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="tipo_ap_he_4"
                          checked={analise.tipo_ap_he_4 ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Banco de horas"
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="tipo_ap_he_5"
                          checked={analise.tipo_ap_he_5 ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Excedentes às"
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      style={{width: '3rem'}}
                      name="tipo_ap_he_5_horas"
                      value={analise.tipo_ap_he_5 ? analise.tipo_ap_he_5_horas : ''}
                      onChange={this.handleAnaliseChange}
                      disabled={!analise.tipo_ap_he_5}
                    />
                  </Grid>
                  <Grid item>
                    <div style={{paddingTop: '1rem'}}>horas mensais</div>
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className={this.classes.checkbox}
                          name="tipo_ap_he_6"
                          checked={analise.tipo_ap_he_6 ? true : false}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Escala de trabalho"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card className={this.classes.section}>
              <CardHeader className={this.classes.cardHeader} title="Salário Base de Cálculo" />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item style={{width: '30rem'}}>
                    <MaterialTable
                      title="Newcap"
                      columns={[
                        { field: 'verba' }
                      ]}
                      data={analise.sal_base_n}
                      options={{
                        search: false,
                        paging: false,
                        sorting: false,
                        actionsColumnIndex: 1,
                        draggable: false
                      }}
                      localization={{
                        body: {
                          emptyDataSourceMessage: 'Nenhuma verba',
                          addTooltip: 'Nova verba',
                          editTooltip: 'Alterar verba',
                          deleteTooltip: 'Excluir verba',
                          editRow: {
                            deleteText: 'Tem certeza que deseja excluir esta verba?',
                            saveTooltip: 'Confirmar',
                            cancelTooltip: 'Cancelar'
                          }
                        },
                        header: {
                          actions: 'Ações'
                        }
                      }}
                      editable={{
                        onRowAdd: newData =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              {
                                const data = analise.sal_base_n;
                                data.push({
                                  id: 0,
                                  parte: 'N',
                                  verba: newData.verba
                                });
                                this.setState({
                                  analise: {
                                    ...this.state.analise,
                                    sal_base_n: data
                                  }
                                });
                                resolve();
                              }
                              resolve();
                            }, 500);
                          }),
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              {
                                const data = analise.sal_base_n;
                                //const index = data.map(e => e.verba).indexOf(oldData.verba);
                                const index = data.indexOf(oldData);
                                data[index] = newData;
                                this.setState({
                                  analise: {
                                    ...this.state.analise,
                                    sal_base_n: data
                                  }
                                });
                                resolve();
                              }
                              resolve();
                            }, 500);
                          }),
                        onRowDelete: oldData =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              {
                                const data = analise.sal_base_n;
                                //const index = data.map(e => e.verba).indexOf(oldData.verba);
                                const index = data.indexOf(oldData);
                                data.splice(index, 1);
                                this.setState({
                                  analise: {
                                    ...this.state.analise,
                                    sal_base_n: data
                                  }
                                });
                                resolve();
                              }
                              resolve();
                            }, 500);
                          })
                      }}
                    />
                  </Grid>
                  <Grid item style={{width: '30rem'}}>
                    <MaterialTable
                      title="Oponente"
                      columns={[
                        { field: 'verba' }
                      ]}
                      data={analise.sal_base_o}
                      options={{
                        search: false,
                        paging: false,
                        sorting: false,
                        actionsColumnIndex: 1,
                        draggable: false
                      }}
                      localization={{
                        body: {
                          emptyDataSourceMessage: 'Nenhuma verba',
                          addTooltip: 'Nova verba',
                          editTooltip: 'Alterar verba',
                          deleteTooltip: 'Excluir verba',
                          editRow: {
                            deleteText: 'Tem certeza que deseja excluir esta verba?',
                            saveTooltip: 'Confirmar',
                            cancelTooltip: 'Cancelar'
                          }
                        },
                        header: {
                          actions: 'Ações'
                        }
                      }}
                      editable={{
                        onRowAdd: newData =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              {
                                const data = analise.sal_base_o;
                                data.push({
                                  id: 0,
                                  parte: 'O',
                                  verba: newData.verba
                                });
                                this.setState({
                                  analise: {
                                    ...this.state.analise,
                                    sal_base_o: data
                                  }
                                });
                                resolve();
                              }
                              resolve();
                            }, 500);
                          }),
                        onRowUpdate: (newData, oldData) =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              {
                                const data = analise.sal_base_o;
                                //const index = data.map(e => e.verba).indexOf(oldData.verba);
                                const index = data.indexOf(oldData);
                                data[index] = newData;
                                this.setState({
                                  analise: {
                                    ...this.state.analise,
                                    sal_base_o: data
                                  }
                                });
                                resolve();
                              }
                              resolve();
                            }, 500);
                          }),
                        onRowDelete: oldData =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              {
                                const data = analise.sal_base_o;
                                //const index = data.map(e => e.verba).indexOf(oldData.verba);
                                const index = data.indexOf(oldData);
                                data.splice(index, 1);
                                this.setState({
                                  analise: {
                                    ...this.state.analise,
                                    sal_base_o: data
                                  }
                                });
                                resolve();
                              }
                              resolve();
                            }, 500);
                          })
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            <Card className={this.classes.section}>
              <CardHeader className={this.classes.cardHeader} title="Verbas Deferidas" />
              <CardContent>
                <MaterialTable
                  columns={[
                    {
                      field: 'verba',
                      render: rowData => {
                        return (
                          <TextField
                            className={this.classes.dataTableCell}
                            value={rowData.verba.descricao}
                          />
                        );
                      },
                      editComponent: props => {
                        return (
                          <TipoVerbaSelect
                            options={deps.tipos_verba}
                            value={props.rowData.verba}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    { field: 'perc' },
                    { field: 'tipo' },
                    {
                      field: 'data_ini',
                      render: rowData => {
                        return (
                          <DateRender
                            className={this.classes.dataTableCell}
                            value={rowData.data_ini}
                          />
                        );
                      },
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: DateField,
                              className: this.classes.dataTablePeriodoEdit
                            }}
                            value={props.rowData.data_ini}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    {
                      field: 'data_fin',
                      render: rowData => {
                        return (
                          <DateRender
                            className={this.classes.dataTableCell}
                            value={rowData.data_fin}
                          />
                        );
                      },
                      editComponent: props => {
                        return (
                          <TextField
                            fullWidth
                            InputProps={{
                              inputComponent: DateField,
                              className: this.classes.dataTablePeriodoEdit
                            }}
                            value={props.rowData.data_fin}
                            onChange={e => props.onChange(e.target.value)}
                          />
                        );
                      }
                    },
                    { field: 'deduz_paga', type: 'boolean' },
                    { field: 'dsr', type: 'boolean' },
                    { field: 'av_prev', type: 'boolean' },
                    { field: 'ferias', type: 'boolean' },
                    { field: 'dec_terc', type: 'boolean' },
                    { field: 'fgts', type: 'boolean' },
                    { field: 'inss', type: 'boolean' },
                    { field: 'ir', type: 'boolean' },
                    { field: 'he', type: 'boolean' },
                    {
                      field: 'int_cred_aut',
                      render: rowData => {
                        return (
                          <TextField
                            className={this.classes.dataTableCell}
                            value={rowData.int_cred_aut}
                          />
                        );
                      },
                    },
                    { field: 'zerar_neg', type: 'boolean' },
                    { field: 'proporci', type: 'boolean' }
                  ]}
                  data={analise.verbas}
                  components={{
                    Header: props => {
                      return (
                        <TableHead>
                          <TableRow>
                            <TableCell>Verba</TableCell>
                            <TableCell>%</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell colSpan={2} align="center">Período de Cálculo</TableCell>
                            <TableCell>Ded.Vrb.Paga</TableCell>
                            <TableCell>DSR</TableCell>
                            <TableCell>Av.</TableCell>
                            <TableCell>Fe.</TableCell>
                            <TableCell>13º</TableCell>
                            <TableCell>FGTS</TableCell>
                            <TableCell>INSS</TableCell>
                            <TableCell>IR</TableCell>
                            <TableCell>HE</TableCell>
                            <TableCell>Int.Créd.Aut.</TableCell>
                            <TableCell>Zerar Neg.</TableCell>
                            <TableCell>Proporc.</TableCell>
                            <TableCell colSpan={2} align="center">Ações</TableCell>
                          </TableRow>
                        </TableHead>
                      );
                    }
                  }}
                  options={{
                    showTitle: false,
                    search: false,
                    paging: false,
                    sorting: false,
                    actionsColumnIndex: 17,
                    draggable: false
                  }}
                  localization={{
                    body: {
                      emptyDataSourceMessage: 'Nenhuma verba',
                      addTooltip: 'Nova verba',
                      editTooltip: 'Alterar verba',
                      deleteTooltip: 'Excluir verba',
                      editRow: {
                        deleteText: 'Tem certeza que deseja excluir esta verba?',
                        saveTooltip: 'Confirmar',
                        cancelTooltip: 'Cancelar'
                      }
                    },
                    header: {
                      actions: 'Ações'
                    }
                  }}
                  editable={{
                    onRowAdd: newData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = analise.verbas;
                            data.push({
                              id: 0,
                              verba: newData.verba,
                              perc: newData.perc,
                              tipo: newData.tipo,
                              data_ini: newData.data_ini,
                              data_fin: newData.data_fin,
                              deduz_paga: newData.deduz_paga,
                              dsr: newData.dsr,
                              av_prev: newData.av_prev,
                              ferias: newData.ferias,
                              dec_terc: newData.dec_terc,
                              fgts: newData.fgts,
                              inss: newData.inss,
                              ir: newData.ir,
                              he: newData.he,
                              int_cred_aut: newData.int_cred_aut,
                              zerar_neg: newData.zerar_neg,
                              proporci: newData.proporci
                            });
                            this.setState({
                              analise: {
                                ...this.state.analise,
                                verbas: data
                              }
                            });
                            resolve();
                          }
                          resolve();
                        }, 500);
                      }),
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = analise.verbas;
                            const index = data.indexOf(oldData);
                            data[index] = newData;
                            this.setState({
                              analise: {
                                ...this.state.analise,
                                verbas: data
                              }
                            });
                            resolve();
                          }
                          resolve();
                        }, 500);
                      }),
                    onRowDelete: oldData =>
                      new Promise((resolve, reject) => {
                        setTimeout(() => {
                          {
                            const data = analise.verbas;
                            const index = data.indexOf(oldData);
                            data.splice(index, 1);
                            this.setState({
                              analise: {
                                ...this.state.analise,
                                verbas: data
                              }
                            });
                            resolve();
                          }
                          resolve();
                        }, 500);
                      })
                  }}
                />
              </CardContent>
            </Card>

          </div>
        ) : null}
      </div>
    );
  }
}

export default withStyles(useStyles)(withRouter(Analise));
