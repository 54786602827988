import React from 'react';
import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

export default function DateField(props) {
  const { inputRef, ...other } = props;

  function mask(rawValue) {
    let mask = [/[0-3]/, /\d/, '/', /[0-1]/, /[1-9]/, '/', /[1-2]/, /\d/, /\d/, /\d/];

    if (rawValue.substring(0, 1) === '3') {
      mask[1] = /[01]/;
    }

    const day = parseInt(rawValue.substring(0, 2));

    if (day === 31) {
      mask[4] = /[0123578]/;
    }

    if (rawValue.substring(3, 4) === '1') {
      if (day === 31) {
        mask[4] = /[02]/;
      } else {
        mask[4] = /[012]/;
      }
    } else if (day > 29) {
      if (day === 31) {
        mask[4] = /[13578]/;
      } else {
        mask[4] = /[13456789]/;
      }
    }

    return mask;
  }

  //console.log({...other});
  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={true}
      mask={mask}
      placeholderChar={'\u2000'}
    />
  );
}

DateField.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
