/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
//import { makeStyles } from '@material-ui/core/styles';

const options = [
  {
    option: 'T',
    descr: 'Pelo Total'
  },
  {
    option: 'P',
    descr: 'No % da Responsabilidade'
  }
];

export default function TipoAprCalcSelect(props) {
  const { style, name, label, onChange } = props;

  const [acValue, setAcValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    acValue && setInputValue(acValue.option ? `${acValue.descr}` : '');
  }, [acValue]);

  return (
    <Autocomplete
      name={name}
      options={options}
      autoHighlight
      getOptionLabel={(option) => option.descr}
      getOptionSelected={(option, value) => option.option === value.option}
      renderInput={(params) => {
        return (
          <TextField
            style={style}
            {...params}
            label={label}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
          />
        );
      }}
      value={acValue}
      onChange={(event, newValue) => {
        if (newValue) {
          setAcValue(newValue);
          onChange(event, newValue);
        }
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
    />
  );
}
